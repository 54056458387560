import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";

// Pages
import HomePage from "../pages/home";
import Login from "../pages/login";
import PageNotFound from "../pages/notFound";

// Components
import HeaderDefault from "../components/header";
import QuizPage from "../pages/quiz";
import PageFinishi from "../pages/finish";
import PageParabens from "../pages/parabens";
import Teste2 from "../pages/teste2";
import { Promotor } from "../pages/promotor";

const CustomRoute = ({ isPrivate, ...rest }) => {
  const globalContext = useGlobalContext();

  if (isPrivate && !globalContext.user) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      {/* Se necessário inserir condição para exibir component header */}
      {/* <HeaderDefault /> */}
      <Route {...rest} />
    </>
  );
};

export default function Routes() {
  return (
    <Switch>
      <CustomRoute exact path="/login" component={Login} />
      {/* Utilizar o atributo isPrivate para exigir usuario logado para continuar */}
      <CustomRoute exact path="/" component={HomePage} />
      <CustomRoute exact path="/promotor" component={Promotor} />
      <CustomRoute exact path="/quiz" component={QuizPage} />
      <CustomRoute exact path="/finish/:id" component={PageFinishi} />
      <CustomRoute exact path="/parabens" component={PageParabens} />
      <CustomRoute exact path="/teste2" component={Teste2} />
      <CustomRoute path="*" component={PageNotFound} />
    </Switch>
  );
}
