import React, { useEffect } from 'react';
import './index.scss';
import { analytics } from '../../utils/firebaseUtils';
import { BOTTOM_COLORS, TOP_COLORS } from '../../utils/colors';
import LogoSvg from '../../components/logoSvg';

export default function PageParabens() {
  useEffect(() => {
    analytics.logEvent('screen_view', { screenName: 'final' });
  }, []);
  const mapFirstHalf = (color, index) => (
    <div
      className="color"
      style={{
        animationDelay: `${index * 0.1}s`,
        position: 'absolute',
        top: 0,
        zIndex: TOP_COLORS.length - index,
        backgroundColor: color,
      }}
      key={index}
    />
  );

  const mapSecondHalf = (color, index) => (
    <div
      className="color2"
      style={{
        animationDelay: `${index * 0.1}s`,
        position: 'absolute',
        bottom: 0,
        zIndex: BOTTOM_COLORS.length * 2 - index,

        backgroundColor: color,
      }}
      key={index}
    />
  );

  function animation() {
    const firstHalf = TOP_COLORS.map(mapFirstHalf);
    const secondHalf = BOTTOM_COLORS.map(mapSecondHalf);

    return (
      <>
        <div className="dividir">{firstHalf}</div>
        <div className="dividir">{secondHalf}</div>
        <div className="content-home" style={{ animationDelay: `1.9s` }}>
          <div className="header">
            <figure>
              <LogoSvg />
            </figure>
            <div>
              <h4>
                PARABÉNS!
                <br />
                VOCÊ ESTÁ
                <br />
                INVESTINDO
                <br />
                EM VC!
              </h4>
            </div>
          </div>
          <div className="person">
            <img
              src={require('../../assets/img/person-home-2.png')}
              alt="Person"
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="content-parabens">
        <div className="animation">{animation()}</div>
      </div>
    </>
  );
}
