import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const Promotor = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.setItem('promotor', true);
    history.replace('/');
  }, [history]);

  return null;
};
