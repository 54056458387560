import React, { useEffect } from 'react';
import './steps.scss';
import QuizComponent from '../../../components/estruturaQuiz';
import { analytics } from '../../../utils/firebaseUtils';

export default function Step03(props) {
  useEffect(() => {
    document.documentElement.style.setProperty('--bg', '#54DCFC');
    document.documentElement.style.setProperty('--bgCard', '#54DCFC');
    document.documentElement.style.setProperty('--textButton', '#54DCFC');

    document.documentElement.style.setProperty('--border', '#465EFF');
    document.documentElement.style.setProperty('--bgPerguntaBox', '#465EFF');
    document.documentElement.style.setProperty('--bgbutton', '#465EFF');
    document.documentElement.style.setProperty('--text', '#465EFF');

    document.documentElement.style.setProperty('--element01', '#FF3131');
    document.documentElement.style.setProperty('--element02', '#FFA10B');
  }, []);

  useEffect(() => {
    analytics.logEvent('screen_view', { screenName: 'quiz_pergunta_2' });
  }, []);

  return <QuizComponent {...props} nextStep={4} />;
}
