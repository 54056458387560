import React, { useEffect, useState } from "react";
import mapeamento from "../utils/mapeamentoCards.json";
import "./teste.scss";

export default function Teste2() {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    // Carregar os dados do arquivo JSON
    setTableData(mapeamento);
  }, []);

  // Função para obter o número máximo de colunas
  const getMaxColumns = () => {
    let maxColumns = 0;
    tableData.forEach((item) => {
      if (parseInt(item.coluna) > maxColumns) {
        maxColumns = parseInt(item.coluna);
      }
    });
    return maxColumns + 1; // Adicionar 1 para incluir a coluna vazia no início
  };

  // Função para agrupar os itens por linha
  const groupItemsByRow = () => {
    const groupedData = {};
    tableData.forEach((item) => {
      const { linha, coluna, valido, cor, text, logo } = item;
      if (!groupedData[linha]) {
        groupedData[linha] = [];
      }
      groupedData[linha][coluna] = {
        valido,
        cor,
        text,
        logo,
        linha,
        coluna,
      };
    });
    return groupedData;
  };

  return (
    <table className="teste-table">
      <tbody>
        {Object.entries(groupItemsByRow()).map(([linha, rowData]) => (
          <tr key={linha}>
            {rowData.map((columnData, index) => (
              <td
                key={index}
                style={{
                  backgroundColor: columnData?.cor,
                  color: columnData?.text,
                  opacity: columnData?.valido? 1 : 0.5
                }}
              >
                {columnData.coluna}x{columnData.linha}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}