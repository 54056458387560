import React, { useEffect } from 'react';
import './steps.scss';
import QuizComponent from '../../../components/estruturaQuiz';
import { analytics } from '../../../utils/firebaseUtils';

export default function Step02(props) {
  useEffect(() => {
    document.documentElement.style.setProperty('--bg', '#465EFF');
    document.documentElement.style.setProperty('--bgCard', '#465EFF');
    document.documentElement.style.setProperty('--textButton', '#465EFF');

    document.documentElement.style.setProperty('--border', '#fff');
    document.documentElement.style.setProperty('--bgPerguntaBox', '#fff');
    document.documentElement.style.setProperty('--bgbutton', '#fff');
    document.documentElement.style.setProperty('--text', '#fff');

    document.documentElement.style.setProperty('--element01', '#FCFC30');
    document.documentElement.style.setProperty('--element02', '#05BF00');
  }, []);

  useEffect(() => {
    analytics.logEvent('screen_view', { screenName: 'quiz_pergunta_1' });
  }, []);

  return <QuizComponent {...props} nextStep={3} />;
}
