export const TOP_COLORS = [
  '#643A8D',
  '#275DAA',
  '#16A64F',
  '#FAE017',
  '#F26D31',
  '#EC2F41',
];

export const BOTTOM_COLORS = [
  '#161018',
  '#714220',
  '#58C7EC',
  '#F284AF',
  '#FFFFFF',
  '#61388A',
  '#FCD601',
];
