import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import './steps.scss';
import { useGlobalContext } from '../../../context/GlobalContext';
import MaskLoading from '../../../components/maskLoading/maskLoading';
import { Firestore, analytics } from '../../../utils/firebaseUtils';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Step01(props) {
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(false);

  const GlobalContext = useGlobalContext();
  const history = useHistory();
  useEffect(() => {
    document.documentElement.style.setProperty('--text', '#fff');
    document.documentElement.style.setProperty('--bg', '#FCFC30');
    document.documentElement.style.setProperty('--border', '#653A8D');
    document.documentElement.style.setProperty('--bgbutton', '#653A8D');
    document.documentElement.style.setProperty('--element01', '#465EFF');
    document.documentElement.style.setProperty('--element02', '#735CC6');
  }, []);

  useEffect(() => {
    analytics.logEvent('screen_view', { screenName: 'formulario_quiz' });
  }, []);

  useEffect(() => {
    if (!GlobalContext.dadosUser.aceiteMarketing) {
      analytics.logEvent('disabled_aceite_marketing');
    }
  }, [GlobalContext.dadosUser.aceiteMarketing]);

  /*  function nextStep() {
     if (formValid()) {
       setNext(true);
       setTimeout(() => {
         props.changeStep(2);
       }, 1000);
     }
   } */

  function nextStep() {
    setLoading(true);
    try {
      Firestore.collection('users')
        .add({
          ...GlobalContext.dadosUser,
          //quiz: GlobalContext.steps,
          date: new Date(),
        })
        .then((response => {
          setNext(true);
          setTimeout(() => {
            /* history.push(`/finish/${idRedireciona()}`); */
            history.push(`/`);
          }, 1000);
        })).catch(() => {
          setLoading(false);
          toast.error("Erro ao salvar Card.")
        })
    } catch (error) {
      setLoading(false);
      toast.error("Erro ao salvar Card.")
    }
  }

  function verificaUser(e) {
    e.preventDefault();
    analytics.logEvent('click_comecar');
    setLoading(true);
    Firestore.collection('users')
      .where('cpf', '==', GlobalContext.dadosUser.cpf.replace(/[^\d]+/g, ''))
      .get()
      .then(response => {
        if (response.size === 0) {
          nextStep();
        } else {
          toast.error('Usuário já registrado!');
          analytics.logEvent('usuario_ja_registrado', {
            cpf: GlobalContext.dadosUser.cpf.replace(/[^\d]+/g, ''),
          });
        }
        setLoading(false);
      });
  }

  function formValid() {
    if (
      GlobalContext.dadosUser.nome === '' ||
      GlobalContext.dadosUser.nome.length < 5
    ) {
      return false;
    }
    if (
      !validarCPF() ||
      !validarTelefone() ||
      !validarEmail() ||
      !validarDataDeNascimento()
    ) {
      return false;
    }

    if (!GlobalContext.dadosUser.termoAceite) {
      return false;
    }

    return true;
  }

  function validarCPF() {
    const cpf = GlobalContext.dadosUser.cpf
      ? GlobalContext.dadosUser.cpf.replace(/[^\d]+/g, '').toString()
      : ''; // Remove caracteres não numéricos

    if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) {
      return false;
    }

    var soma = 0;
    for (var i = 0; i < 9; i++) {
      soma += parseInt(cpf.charAt(i)) * (10 - i);
    }

    var resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(9))) {
      return false;
    }

    soma = 0;
    for (var j = 0; j < 10; j++) {
      soma += parseInt(cpf.charAt(j)) * (11 - j);
    }

    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== parseInt(cpf.charAt(10))) {
      return false;
    }
    return true;
  }

  function validarTelefone() {
    const telefone = GlobalContext.dadosUser.telefone
      ? GlobalContext.dadosUser.telefone.replace(/[^\d]+/g, '').toString()
      : ''; // Remove caracteres não numéricos

    if (telefone.length !== 11) {
      return false;
    }

    if (telefone.charAt(2) !== '9') {
      return false;
    }
    return true;
  }

  function validarEmail() {
    const email = GlobalContext.dadosUser.email
      ? GlobalContext.dadosUser.email.toString()
      : ''; // Remove caracteres não numéricos
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Expressão regular para validar o formato do e-mail

    if (!emailRegex.test(email)) {
      return false;
    }

    if (email.includes('..')) {
      return false;
    }

    var domain = email.split('@')[1];
    if (domain.includes('..')) {
      return false;
    }
    return true;
  }

  function validarDataDeNascimento() {
    const dataDeNascimento = GlobalContext.dadosUser.dataDeNascimento
      ? GlobalContext.dadosUser.dataDeNascimento.toString()
      : '';

    const [day, month, year] = dataDeNascimento.split('/');

    const parsedDay = Number(day);
    const parsedMonth = Number(month);
    const parsedYear = Number(year);

    if (
      isNaN(parsedMonth) ||
      isNaN(parsedDay) ||
      isNaN(parsedYear) ||
      parsedMonth < 1 ||
      parsedMonth > 12 ||
      parsedDay < 1 ||
      parsedDay > 31 ||
      parsedYear < 1000 ||
      parsedYear > 9999
    ) {
      return false;
    }

    return true;
  }

  return (
    <>
      <div className="step 01">
        <div className="content-cardStep">
          <div className={`card-form ${next && 'close'}`}>
            <form onSubmit={verificaUser} className="form">
              <input
                type="text"
                placeholder="Nome completo"
                value={GlobalContext.dadosUser.nome}
                autoCapitalize="words"
                autoComplete="name"
                onChange={value =>
                  GlobalContext.setDadosUser({
                    ...GlobalContext.dadosUser,
                    nome: value.target.value,
                  })
                }
              />
              <InputMask
                type="text"
                placeholder="CPF"
                mask="999.999.999-99"
                inputMode="numeric"
                value={GlobalContext.dadosUser.cpf || ''}
                onChange={value =>
                  GlobalContext.setDadosUser({
                    ...GlobalContext.dadosUser,
                    cpf: value.target.value,
                  })
                }
              />
              <InputMask
                type="text"
                placeholder="Data de nascimento"
                mask="99/99/9999"
                inputMode="numeric"
                value={GlobalContext.dadosUser.dataDeNascimento || ''}
                autoComplete="bday-day"
                onChange={value =>
                  GlobalContext.setDadosUser({
                    ...GlobalContext.dadosUser,
                    dataDeNascimento: value.target.value,
                  })
                }
              />
              <InputMask
                type="text"
                placeholder="Telefone"
                mask="(99) 9 9999-9999"
                inputMode="tel"
                value={GlobalContext.dadosUser.telefone || ''}
                autoComplete="tel-national"
                onChange={value =>
                  GlobalContext.setDadosUser({
                    ...GlobalContext.dadosUser,
                    telefone: value.target.value,
                  })
                }
              />
              <input
                type="email"
                placeholder="E-mail"
                value={GlobalContext.dadosUser.email}
                autoComplete="email"
                inputMode="email"
                onChange={value =>
                  GlobalContext.setDadosUser({
                    ...GlobalContext.dadosUser,
                    email: value.target.value,
                  })
                }
              />
              <div className="option">
                <label>
                  <input
                    type="checkbox"
                    autoComplete="off"
                    placeholder="E-mail"
                    checked={GlobalContext.dadosUser.aceiteMarketing}
                    onChange={() =>
                      GlobalContext.setDadosUser(prev => ({
                        ...prev,
                        aceiteMarketing: !prev.aceiteMarketing,
                      }))
                    }
                  />
                  <span>
                    Aceito receber comunicações de promoções e marketing
                  </span>
                </label>
              </div>
              <div className="option">
                <label>
                  <input
                    type="checkbox"
                    autoComplete="off"
                    placeholder="E-mail"
                    checked={GlobalContext.dadosUser.termoAceite}
                    onChange={() =>
                      GlobalContext.setDadosUser(prev => ({
                        ...prev,
                        termoAceite: !prev.termoAceite,
                      }))
                    }
                  />
                  <span>
                    Li e concordo com o{' '}
                    <a
                      href="./INSTRUMENTO_PARTICULAR_DE_CESSA)_DE_USO_DE_IMAGEM_INTERPRETACAO_E_SOM_DE_VOZ_A_TITULO _RATUITO.pdf"
                      target="_blank"
                    >
                      termo de direito
                    </a>{' '}
                    de uso de imagem
                  </span>
                </label>
              </div>
              <hr />
              <div className="option">
                <small>
                  Este teste não tem base científica. É uma brincadeira pra
                  gente entender a nossa relação com o dinheiro que, assim como
                  a sua vivência LGBTQIAPN+, é envolta de tabus, não é mesmo?
                </small>
              </div>
              <div className="action">
                <button disabled={!formValid()} type="submit">
                  {/* Começar */}Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className={`animation-step ${next && 'close'}`}>
          <div className="element01"></div>
          <div className="element02"></div>
          <div className="revela"></div>
        </div>
      </div>
      {loading && (
        <div className="global-loading">
          <MaskLoading />
        </div>
      )}
    </>
  );
}
