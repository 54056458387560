import React from 'react';
import './steps.scss';

export default function StepsStatus(props) {
  return (
    <div className={`component-steps ${props.currentStep === 3 && 'dark'}`}>
      <p>
        Pergunta {props.currentStep - 1}/<small>3</small>
      </p>
    </div>
  );
}
