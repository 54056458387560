import React, { useEffect, useState } from 'react';
import './index.scss';
import { useGlobalContext } from '../../context/GlobalContext';
import StepsStatus from '../../pages/quiz/steps/steps';
import { ReactComponent as Check } from '../../assets/img/check.svg';
import { analytics } from '../../utils/firebaseUtils';

export default function QuizComponent(props) {
  const [next, setNext] = useState(false);

  const GlobalContext = useGlobalContext();

  function checkDisabled() {
    if (
      GlobalContext.steps[props.currentStep - 2].perguntas.find(e => e.active)
    ) {
      return false;
    }
    return true;
  }

  function nextStep() {
    if (props.finish) {
      props.changeStep();
      return;
    }

    setNext(true);
    setTimeout(() => {
      props.changeStep(props.nextStep);
    }, 1000);
  }

  const onClickQuestion = (e, index) => {
    analytics.logEvent('click_pergunta_quiz', {
      enunciado: GlobalContext.steps[props.currentStep - 2].enunciado,
      pergunta: e.pergunta,
    });
    props.selecionarPergunta(index);
  };

  useEffect(() => {
    if (props.goNext) {
      setNext(true);
    }
  }, [props.goNext]);

  return (
    <>
      <div className="step step-02">
        <div className="content-cardStep">
          <div>
            <div className={`controll-steps ${next && 'close'}`}>
              {props.currentStep < 4 && (
                <StepsStatus
                  steps={GlobalContext.steps}
                  currentStep={props.currentStep}
                />
              )}
            </div>
            <div
              className={`card-form ${next && 'close'} ${
                GlobalContext.steps[props.currentStep - 2].perguntasBox &&
                'quiz-Box'
              }`}
            >
              <div className="form">
                <div className="quiz">
                  <h2>
                    {GlobalContext.steps[props.currentStep - 2].enunciado}
                  </h2>
                  <div className="respostas">
                    {GlobalContext.steps[props.currentStep - 2].perguntas.map(
                      (e, index) => (
                        <div
                          key={e.pergunta}
                          className={e.active && 'active'}
                          onClick={() => onClickQuestion(e, index)}
                        >
                          <span>{(index + 10).toString(36).toUpperCase()}</span>
                          <div className="select">
                            <Check />
                          </div>
                          <p>{e.pergunta}</p>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="action">
                  <button disabled={checkDisabled()} onClick={nextStep}>
                    Próximo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`animation-step ${next && 'close'}`}>
          <div className="element01"></div>
          <div className="element02"></div>
          <div className="revela"></div>
        </div>
      </div>
    </>
  );
}
