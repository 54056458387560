import React, { useEffect, useState } from 'react';
import './index.scss';
import LogoSvg from '../../components/logoSvg';
import { useHistory } from 'react-router-dom';
import { analytics } from '../../utils/firebaseUtils';
import { BOTTOM_COLORS, TOP_COLORS } from '../../utils/colors';

export default function HomePage() {
  const [close, setClose] = useState(false);

  const history = useHistory();

  const mapFirstHalf = (color, index) => (
    <div
      className="color"
      style={{
        animationDelay:  `${(close ? TOP_COLORS.length - index : index) * 0.1}s`,
        position: 'absolute',
        top: 0,
        zIndex: TOP_COLORS.length - index,
        backgroundColor: color,
      }}
      key={index}
    />
  );

  const mapSecondHalf = (color, index) => (
    <div
      className="color2"
      style={{
        animationDelay: `${(close ? BOTTOM_COLORS.length - index : index) * 0.1}s`,
        position: 'absolute',
        bottom: 0,
        zIndex: BOTTOM_COLORS.length * 2 - index,

        backgroundColor: color,
      }}
      key={index}
    />
  );

  function animation() {
    const firstHalf = TOP_COLORS.map(mapFirstHalf);
    const secondHalf = BOTTOM_COLORS.map(mapSecondHalf);

    return (
      <>
        <div className={`dividir ${close ? 'close' : ''}`}>{firstHalf}</div>
        <div className={`dividir ${close ? 'close' : ''}`}>{secondHalf}</div>
        <div className={`content-home ${close ? 'close' : ''}`}>
          <div className="header">
            <div>
              <figure>
                <LogoSvg />
              </figure>
              <h4>
                No BB, <b>todo</b> <br /> <b>mundo</b> tem perfil <br /> para{' '}
                <b>investir</b>
              </h4>
              <h3>Invista em</h3>
              <h3 className="you">
                <b>você</b>
              </h3>
              <div className="button-wrapper">
                <button onClick={goToQuiz}>COMEÇAR</button>
              </div>
            </div>
          </div>
          <div className="person">
            <img
              src={require(`../../assets/img/person-home.png`)}
              alt="Person"
            />
          </div>
        </div>
      </>
    );
  }

  function goToQuiz() {
    fetch('https://send-email-service-ako5.onrender.com').catch(err => console.error(err));
    analytics.logEvent('click_descubra_como');
    setClose(true);
    setTimeout(() => history.push('/quiz'), 850);
  }

  useEffect(() => {
    analytics.logEvent('screen_view', { screenName: 'inicial' });
  }, []);

  return (
    <>
      <div className={`content ${close ? 'close' : ''}`}>
        <div className="animation">{animation()}</div>
      </div>
    </>
  );
}
