import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { auth } from './utils/firebaseUtils';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-alice-carousel/lib/alice-carousel.css";

import Routes from './routes/routes';
import history from './routes/history';
import './App.scss'

import { useGlobalContext } from './context/GlobalContext';

import MaskLoading from './components/maskLoading/maskLoading';

import Cores from './utils/cores.json';

function App() {
  const [initializing, setInitializing] = useState(true);
  const globalContext = useGlobalContext();

  useEffect(() => {
    const css = generateCSS();
    const styleTag = createStyleTag(css);
    appendStyleTag(styleTag);

    const viewportHeight = window.innerHeight;
    document.documentElement.style.setProperty('--height', viewportHeight + "px");

    return () => {
      removeStyleTag(styleTag);
    };
  }, []);

  useEffect(() => {
    const onResize = () => {
      const viewportHeight = window.innerHeight;
      document.documentElement.style.setProperty('--height', viewportHeight + "px");
    }

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, []);

  function generateCSS() {
    let cssText = "";

    Cores.forEach((e, index) => {
      const className = `.color-${index}`;
      cssText += `
        ${className} {
          background-color: ${e.color};
        }
      `;
    });

    return cssText;
  }

  function createStyleTag(css) {
    const styleTag = document.createElement("style");
    styleTag.appendChild(document.createTextNode(css));
    return styleTag;
  }

  function appendStyleTag(styleTag) {
    document.head.appendChild(styleTag);
  }

  function removeStyleTag(styleTag) {
    document.head.removeChild(styleTag);
  }

  useEffect(() => {
    const subscriber = auth().onAuthStateChanged(onAuthStateChanged);
    return subscriber;
  }, []);

  async function onAuthStateChanged(user) {
    if (user && user.uid) {
      // Usuário logado
    } else if (initializing) {
      // Usuário não logado
    }
    setInitializing(false);
  }

  if (initializing) {
    return <MaskLoading />;
  }


  return (
      <Router history={history}>
        <Routes />
      </Router>
  );
}

export default App;