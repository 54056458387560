import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LogoSvg from '../../components/logoSvg';

import { ReactComponent as BBDonationIcon } from '../../assets/img/bb-donation.svg';
import { ReactComponent as PointsIcon } from '../../assets/img/points.svg';
import { ReactComponent as VisaDonationIcon } from '../../assets/img/visa-donation.svg';
import { ReactComponent as ZeroFeeIcon } from '../../assets/img/zero-fee.svg';

import ModeloCuidadoso from '../../assets/img/modelos/modelo-cuidadoso.png';
import ModeloDescomplicado from '../../assets/img/modelos/modelo-descomplicado.png';
import ModeloInfluenciador from '../../assets/img/modelos/modelo-influenciador.png';
import ModeloSemTempo from '../../assets/img/modelos/modelo-sem-tempo.png';

import VideoDecidida from '../../assets/videos/BB_INVESTIMENTOS_DECIDIDA_15s_16x9.mp4';
import VideoArretado from '../../assets/videos/BB_INVESTIMENTOS_EMPRESARIO_15s_16x9.mp4';
import VideoInfluenciador from '../../assets/videos/BB_INVESTIMENTOS_INFLUENCIADOR_15s_16x9.mp4';
import VideoSemTempo from '../../assets/videos/BB_INVESTIMENTOS_SEM_TEMPO_15s_16x9.mp4';
import VideoCuidadosa from '../../assets/videos/BB_INVESTIMENTOS_CUIDADOSA_30s_16x9.mp4';

import { ReactComponent as MiniLogo } from '../../assets/img/mini-logo.svg';

import Botton from '../../assets/img/botton.png';

import Brinde1 from '../../assets/img/brindes/brinde1.png';
import Brinde2 from '../../assets/img/brindes/brinde2.png';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from 'react-slick';
import { analytics } from '../../utils/firebaseUtils';

import './index.scss';

const profiles = {
  SemTempo: {
    title: 'Sem Tempo',
    description:
      'Você é aquele que vive no corre. Entre uma reunião e outra, mal tem tempo para pesquisar sobre investimentos. Busca praticidade acima de tudo, tipo pedir comida pelo app, porque cozinhar, nem pensar!',
    image: ModeloSemTempo,
    video: VideoSemTempo,
  },
  Influenciador: {
    title: 'Influenciador',
    description:
      'Você adora se jogar nas tendências e tem sempre um olho nos vídeos de especialistas e nos posts do Instagram. Tá sempre pronto pra arriscar um pouco mais, afinal, quem não arrisca não petisca!',
    image: ModeloInfluenciador,
    video: VideoInfluenciador,
  },
  Cuidadoso: {
    title: 'Cuidadoso',
    description:
      'Você prefere jogar pelo seguro. Antes de qualquer passo, quer orientação e conselhos, igual pedir opinião sobre look antes de sair de casa. Precisa daquela segurança extra pra se sentir bem.',
    image: ModeloCuidadoso,
    video: VideoCuidadosa,
  },
  Descomplicado: {
    title: 'Descomplicado',
    description:
      'Você é independente e gosta das coisas simples. Já investe, mas sem muita complicação. Prefere opções fáceis e sem muito drama, tipo um cafezinho preto: básico e direto ao ponto.',
    image: ModeloDescomplicado,
    video: VideoCuidadosa,
  },
  Decidido: {
    title: 'Decidido',
    description:
      'Você ama acompanhar o mercado e tomar suas próprias decisões. Não gosta que ninguém diga o que fazer com seu dinheiro. Confiança é seu sobrenome, igual quando escolhe a playlist da festa e arrasa.',
    image: ModeloInfluenciador,
    video: VideoDecidida,
  },
  Arretado: {
    title: 'Arretado',
    description:
      'Você entende tudo de investimentos e está sempre em busca das melhores oportunidades. Nada passa despercebido. É tipo aquele amigo que sabe todas as fofocas do bairro: sempre atualizado e ligado em tudo.',
    image: ModeloSemTempo,
    video: VideoArretado,
  },
};

const gifts = [Brinde1, Brinde2];

const settings = {
  dots: false,
  infinite: false,
  asNavFor: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: true,
  initialSlide: 0,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export default function PageFinishi() {
  // const [conteudo, setConteudo] = useState(SemPerrengue);
  const [promotor, setPromotor] = useState(false);
  const history = useHistory();
  const { id } = useParams();

  function goTo() {
    analytics.logEvent('click_finalizar', { perfil: id });
    history.push('/parabens');
  }

  const goHome = () => {
    analytics.logEvent('click_nova_pesquisa');
    history.push('/');
  };

  const onClickCard = () => {
    analytics.logEvent('click_cartao_orgulho', { perfil: id });
    window.open(
      'https://kr7s.adj.st/opr?cm49eyJvcHIiOiJBQlJOT1ZBQ1RBIn0=&adj_t=1cv006pm',
      '_blank'
    );
  };

  const onClickVideo = () => {
    analytics.logEvent('click_video', { perfil: id });
  };

  const profile = profiles[id] ?? {};

  useEffect(() => {
    analytics.logEvent('screen_view', { screenName: `perfil_${id}` });
  }, [id]);

  useEffect(() => {
    const p = localStorage.getItem('promotor');
    if (p) {
      setPromotor(true);
    }
  }, []);

  return (
    <div className="page-finish">
      <section className="profile">
        <h4>Seu perfil é</h4>
        <h2>{profile.title}</h2>
        <img src={profile.image} alt={profile.title} />
        <p className="description">{profile.description}</p>
      </section>
      <section className="invista">
        <div className="row">
          <div>
            <h2>Invista</h2>
            <h2 className="right">em vc.</h2>
          </div>
          <img src={Botton} alt="Botton" />
        </div>
        <p>
          Procure nosso time de especialistas na <b>23ª Feira da Diversidade</b>{' '}
          e tire suas dúvidas sobre investimentos.
        </p>
      </section>

      <section className="invesTalk">
        <p>Comece por aqui</p>
        <div className="logo">
          <h6>InvesTalk</h6>
          <MiniLogo />
        </div>
        {/* <Slider {...settings}>{renderCarouselItems()}</Slider> */}
        {promotor ? (
          <p className="promotor">
            Acesse seu e-mail e confira os conteúdos do InvesTalk BB que
            preparamos especialmente para o seu perfil.
          </p>
        ) : (
          <video controls onClick={onClickVideo}>
            <source src={profile.video} type="video/mp4" />
          </video>
        )}
      </section>

      <section className="card">
        <h3>Faça seu</h3>
        <h2>Cartão Orgulho</h2>
        <img src={require('../../assets/img/card.png')} alt="Card" />
        <button onClick={onClickCard}>clique aqui</button>
        <p className="subTitle">
          Um cartão com tantos benefícios, que dá até orgulho de usar.
        </p>
        <div className="benefits">
          <div className="card-benefit">
            <div className="card-benefit-content">
              <ZeroFeeIcon />
              <p>Tarifa zero para emissões no mês de junho</p>
            </div>
          </div>
          <div className="card-benefit">
            <div className="card-benefit-content">
              <PointsIcon />
              <p>
                1000 pontos Livelo para quem ativar o Cartão Orgulho no app BB
                no mês de junho
              </p>
            </div>
          </div>
          <div className="card-benefit">
            <div className="card-benefit-content">
              <VisaDonationIcon />
              <p>
                Visa Causas vai doar R$ 0,01 por transação para a Casa 1, que
                oferece apoio psicológico gratuito a jovens LGBTQIAPN+ entre 18
                e 25 anos.
              </p>
            </div>
          </div>
          <div className="card-benefit">
            <div className="card-benefit-content">
              <BBDonationIcon />
              <p>
                O BB vai doar R$14,00 por cada cartão feito + R$0,01 por
                transação para a Fundação BB, que destinará o recurso para apoio
                à causa.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="gift">
        <h2>Retire seu brinde</h2>
        <h2>no estande.</h2>
        <p>
          No final do arco-íris tem um brinde esperando por você. Mostre essa
          tela para um promotor do BB no nosso estande e retire o seu.
        </p>
        <div className="slider">
          <Slider {...settings}>
            {gifts.map((gift, index) => (
              <div className="carousel-item" key={index}>
                <img
                  className="gift-image"
                  key={index + 1}
                  src={gift}
                  alt="Brinde"
                />
              </div>
            ))}
          </Slider>
        </div>
        <span>Distribuição de brindes limitada ao estoque de cada item.</span>
        <button onClick={goTo}>FINALIZAR</button>
        <button onClick={goHome}>NOVA PESQUISA</button>
        <div className="bottom">
          <LogoSvg />
        </div>
      </section>
    </div>
  );
}
