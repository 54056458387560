import React, { useState, useContext, createContext } from 'react';

const GlobalContext = createContext();

export function GlobalProvider({ children }) {
  const context = useGlobalProvider();
  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};

function useGlobalProvider() {
  const [user, setUser] = useState();
  const [steps, setStep] = useState([
    {
      enunciado:
        'Quando o assunto é guardar e investir dinheiro, qual é o seu maior objetivo hoje?',
      perguntasBox: true,
      perguntas: [
        {
          pergunta: 'Quero começar, fazer meu primeiro investimento e brilhar!',
          active: false,
        },
        {
          pergunta:
            'Já comecei a guardar, mas tô mais perdido que hétero em boate LGBT.',
          active: false,
        },
        {
          pergunta:
            'Já tenho um dinheirinho investido, mas quero ver minha fortuna brilhar mais que glitter na Parada.',
          active: false,
        },
        {
          pergunta:
            'Tô em busca da independência financeira e quero aquele dinheirinho caindo na conta todo mês.',
          active: false,
        },
        {
          pergunta:
            'Já tô pensando no patrimônio que vou deixar pra minha família.',
          active: false,
        },
        {
          pergunta:
            'Estou mais perdido que sapatilha em balada. Não faço ideia.',
          active: false,
        },
      ],
    },
    {
      enunciado: 'Com qual destas vibes você mais se identifica?',
      perguntasBox: true,
      perguntas: [
        {
          pergunta:
            'Sou iniciante, não tenho tempo pra me informar, então busco praticidade.',
          active: false,
        },
        {
          pergunta:
            'Sou iniciante, mas vejo conteúdos de investimento e quero me jogar.',
          active: false,
        },
        {
          pergunta:
            'Não tenho segurança pra investir e prefiro que me digam o que fazer.',
          active: false,
        },
        {
          pergunta:
            'Busco minha independência e já invisto, mas de forma simples e sem muito drama.',
          active: false,
        },
        {
          pergunta:
            'Gosto de acompanhar o mercado, mas sou eu que decido onde investir.',
          active: false,
        },
        {
          pergunta:
            'Entendo do assunto e estou sempre de olho no mercado em busca de babados e melhores ganhos.',
          active: false,
        },
      ],
    },
    {
      enunciado: 'Se rolar uma dúvida sobre investimentos, o que você faz?',
      perguntasBox: true,
      perguntas: [
        {
          pergunta: 'Entro no aplicativo do banco e dou close sozinha.',
          active: false,
        },
        {
          pergunta: 'Pego dicas com amigues e/ou dou aquele Google esperto.',
          active: false,
        },
        {
          pergunta:
            'Procuro em sites e perfis bapho que já conheço e acompanho.',
          active: false,
        },
        {
          pergunta:
            'Até busco na internet, mas não abro mão de falar com um especialista.',
          active: false,
        },
        {
          pergunta: 'Vou direto no meu gerente, porque confiança é tudo.',
          active: false,
        },
      ],
    },
  ]);
  const [dadosUser, setDadosUser] = useState({
    nome: '',
    cpf: null,
    telefone: null,
    dataDeNascimento: null,
    email: '',
    termoAceite: true,
    aceiteMarketing: true,
    descricao: '',
  });

  return {
    user,
    setUser,
    steps,
    setStep,
    dadosUser,
    setDadosUser,
  };
}
