import React from "react";
import "./index.scss";

export default function PageNotFound() {
  return (
    <>
      PageNotFound
    </>
  );
}
