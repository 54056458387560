import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import QuizComponent from '../../../components/estruturaQuiz';
import MaskLoading from '../../../components/maskLoading/maskLoading';
import { useGlobalContext } from '../../../context/GlobalContext';
import { Firestore, analytics } from '../../../utils/firebaseUtils';
import './steps.scss';

export default function Step04(props) {
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(false);

  const GlobalContext = useGlobalContext();
  const history = useHistory();

  useEffect(() => {
    document.documentElement.style.setProperty('--bg', '#00EBD0');
    document.documentElement.style.setProperty('--bgCard', '#00EBD0');
    document.documentElement.style.setProperty('--textButton', '#00EBD0');

    document.documentElement.style.setProperty('--border', '#465EFF');
    document.documentElement.style.setProperty('--bgPerguntaBox', '#465EFF');
    document.documentElement.style.setProperty('--bgbutton', '#465EFF');
    document.documentElement.style.setProperty('--text', '#465EFF');

    document.documentElement.style.setProperty('--element01', '#6D2E18');
    document.documentElement.style.setProperty('--element02', '#000000');
  }, []);

  const getProfile = () => {
    const arrayPerguntas = GlobalContext.steps[1];
    const filteredItems = arrayPerguntas.perguntas.findIndex(
      item => item.active
    );

    switch (filteredItems) {
      case 0:
        return 'SemTempo';
      case 1:
        return 'Influenciador';
      case 2:
        return 'Cuidadoso';
      case 3:
        return 'Descomplicado';
      case 4:
        return 'Decidido';
      case 5:
        return 'Arretado';
      default:
        return '';
    }
  };

  const onChangeStep = async () => {
    const profile = getProfile();
    const userData = {
      ...GlobalContext.dadosUser,
      cpf: GlobalContext.dadosUser.cpf.replace(/[^\d]+/g, ''),
      quiz: GlobalContext.steps,
      profile,
      date: new Date(),
    };

    analytics.logEvent('click_finalizar_quiz', { profile });

    setLoading(true);
    try {
      await Firestore.collection('users').add(userData);
      fetch('https://send-email-service-ako5.onrender.com/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: userData.email,
          profile,
        })
      }).catch(err => console.log(err));

      analytics.logEvent('usuario_criado');
      setLoading(false);
      setNext(true);
      setTimeout(() => {
        history.push(`/finish/${profile}`);
      }, 1000);
    } catch (error) {
      setLoading(false);
      analytics.logEvent('erro_ao_salvar_quiz', userData);
      toast.error('Erro ao salvar Quiz.');
    }
  };

  useEffect(() => {
    analytics.logEvent('screen_view', { screenName: 'quiz_pergunta_3' });
  }, []);

  return (
    <>
      <QuizComponent
        {...props}
        changeStep={onChangeStep}
        goNext={next}
        finish
      />
      {loading && (
        <div className="global-loading">
          <MaskLoading />
        </div>
      )}
    </>
  );
}
