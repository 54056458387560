import React, { useEffect, useState } from "react";
import "./steps.scss";
import MaskLoading from "../../../components/maskLoading/maskLoading";
import { Firestore } from "../../../utils/firebaseUtils";
import { useGlobalContext } from "../../../context/GlobalContext";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export default function Step09(props) {
  const [next, setNext] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const GlobalContext = useGlobalContext();

  useEffect(() => {
    document.documentElement.style.setProperty('--bg', '#FCFC30');
    document.documentElement.style.setProperty('--bgCard', '#FCFC30');
    document.documentElement.style.setProperty('--textButton', '#FCFC30');

    document.documentElement.style.setProperty('--border', '#F97A70');
    document.documentElement.style.setProperty('--bgbutton', '#F97A70');
    document.documentElement.style.setProperty('--text', '#F97A70');

    document.documentElement.style.setProperty('--element01', '#735CC6');
    document.documentElement.style.setProperty('--element02', '#FCFC30');
  }, []);

  function idRedireciona() {
    const arrayPerguntas = GlobalContext.steps[GlobalContext.steps.length -1];
    const filteredItems = arrayPerguntas.perguntas.findIndex((item) => item.active);

    switch (filteredItems) {
      case 0:
        return 'SemPerrengue'
      case 1:
        return 'JuntarPinkMoney'
      case 2:
        return 'JuntarDinheiroViagem'
      case 3:
        return 'JuntarTransicao'
      case 4:
        return 'JuntarCasamento'
      case 5:
        return 'LivarImpulsividade'
      case 6:
        return 'JuntarDimdim'
      case 7:
        return 'CuidaPinkMoney'
      case 8:
        return 'LivrarDividas'
      default:
        return ''
    }
  }

  function nextStep() {
    setLoading(true);
    try {
      Firestore.collection('users')
        .add({
          ...GlobalContext.dadosUser,
          quiz: GlobalContext.steps,
          date: new Date(),
        })
        .then((response => {
          setNext(true);
          setTimeout(() => {
            history.push(`/finish/${idRedireciona()}`);
          }, 1000);
        })).catch(() => {
          setLoading(false);
          toast.error("Erro ao salvar Card.")
        })
    } catch (error) {
      setLoading(false);
      toast.error("Erro ao salvar Card.")
    }
  }

  function validNext() {
    if (
      GlobalContext.dadosUser.descricao.length < 10 ||
      GlobalContext.dadosUser.descricao.length > 220
    ) {
      return false;
    }
    return true;
  }

  return <>
    <div className="step 01">
      <div className="content-cardStep">
        <div className={`card-form ${next && 'close'}`}>
          <div className="form">
            <h2>em que você desejaria <br /> investir seu pink money?</h2>
            <textarea
              rows="10"
              placeholder="Conta pra gente em 220 caracteres."
              value={GlobalContext.dadosUser.descricao}
              onChange={(value) => GlobalContext.setDadosUser({ ...GlobalContext.dadosUser, descricao: value.target.value })}
            ></textarea>
            <span className="contador">
              {GlobalContext.dadosUser.descricao.length}/220
            </span>
            <div className="action">
              <button onClick={nextStep} disabled={!validNext()}>Enviar</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`animation-step ${next && 'close'}`}>
        <div className="element01"></div>
        <div className="element02"></div>
        <div className="revela"></div>
      </div>
    </div>
    {loading &&
      <div className="global-loading">
        <MaskLoading />
      </div>
    }
  </>;
}
